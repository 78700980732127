var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.wrapper},[_c('div',{class:[_vm.$style.sidebar, _vm.isCollapse ? '' : _vm.$style.sidebar__unCollapse]},[_c('div',{class:_vm.$style.top},[_c('span',{staticClass:"truncate",class:_vm.$style.title},[_vm._v(_vm._s(_vm.NVRName || '摄像头列表'))]),(_vm.isNVRList)?_c('span',{class:_vm.$style.backUpAll,on:{"click":_vm.onChangeDeviceList}},[_c('a-icon',{class:_vm.$style.icon,attrs:{"type":"rollback"}}),_vm._v("全部设备")],1):_vm._e(),_c('a-icon',{class:_vm.$style.collapse,attrs:{"type":"menu-fold"},on:{"click":function($event){return _vm.collapse(false)}}})],1),_c('div',{class:_vm.$style.tags},[_c('div',{class:_vm.$style.tag,on:{"click":function($event){return _vm.onChangeStatus('')}}},[_c('div',{class:_vm.$style.name},[_vm._v("总数")]),_c('div',{class:_vm.$style.num},[_vm._v(_vm._s(_vm.onlineNum + _vm.offlineNum))])]),_c('div',{class:_vm.$style.tag,on:{"click":function($event){return _vm.onChangeStatus(1)}}},[_c('div',{class:_vm.$style.name},[_vm._v("在线")]),_c('div',{class:_vm.$style.num},[_vm._v(_vm._s(_vm.onlineNum))])]),_c('div',{class:_vm.$style.tag,on:{"click":function($event){return _vm.onChangeStatus(0)}}},[_c('div',{class:_vm.$style.name},[_vm._v("离线")]),_c('div',{class:[_vm.$style.num, _vm.$style.offline]},[_vm._v(_vm._s(_vm.offlineNum))])])]),_c('div',{class:_vm.$style.deviceList},[_c('x-scroll-view',{staticClass:"screen_scrollbar",class:_vm.$style.list,attrs:{"disabled":_vm.isEnd,"lowerThreshold":80,"immediateCheck":false},on:{"scrolltolower":_vm.loadMoreDevices}},_vm._l((_vm.deviceList),function(item,index){return _c('div',{key:index,class:[
            _vm.$style.device,
            _vm.activeIndex === index && _vm.gridNum === 1 ? _vm.$style.selected : '',
          ],on:{"click":function($event){return _vm.onChangeVideo(item, index)}}},[_c('i',{class:_vm.$style.status,style:({ background: item.online ? '#00c46d' : '#fa5151' })}),_c('span',{staticClass:"truncate",class:_vm.$style.name,style:({ maxWidth: _vm.isNVRList ? '3rem' : '2.1rem' })},[_vm._v(_vm._s(item.deviceName))]),(!_vm.isNVRList)?_c('span',{class:_vm.$style.des},[_vm._v(_vm._s(item.deviceType ? _vm.exchangeValue(item.deviceType, _vm.monitorTypes) : ''))]):_vm._e(),(item.deviceType === 'NVR' && !_vm.isNVRList)?_c('a-icon',{class:_vm.$style.icon,attrs:{"type":"apartment"},on:{"click":function($event){$event.stopPropagation();return _vm.handleChangeNVRList(item.pkId, '', item.deviceName)}}}):_vm._e()],1)}),0)],1)]),_c('div',{class:_vm.$style.main},[_c('div',{class:_vm.$style.top},[_c('div',{class:_vm.$style.title},[(!_vm.isCollapse)?_c('a-icon',{class:_vm.$style.unCollapse,attrs:{"type":"menu-unfold"},on:{"click":function($event){return _vm.collapse(true)}}}):_vm._e(),_c('span',[_vm._v("视频监控区")])],1),_c('div',{class:_vm.$style.gridIcons},_vm._l((_vm.gridList),function(item){return _c('div',{key:item.gridNum,class:[
            _vm.$style.circle,
            _vm.gridNum === item.gridNum ? _vm.$style.selected : '',
          ],on:{"click":function($event){return _vm.handleChangeLayout(item.gridNum)}}},[_c('ali-icon',{attrs:{"type":item.icon}})],1)}),0)]),(_vm.isReload)?_c('div',{class:_vm.$style.videoGrid},_vm._l((_vm.gridNum),function(item,index){return _c('div',{key:index,class:_vm.$style.card,style:(_vm.gridObj[_vm.gridNum])},[_c('div',{class:_vm.$style.videoWrap},[(_vm.videoList[index])?_c('span',{staticStyle:{"font-size":"0.16rem"}},[_vm._v(_vm._s(_vm.videoList[index].deviceName))]):_vm._e(),(
              _vm.videoList[index]?.channelList.length > 1 &&
                _vm.videoList[index] &&
                !_vm.isNVRList
            )?_c('a-select',{class:_vm.$style.channelSelect,on:{"change":function($event){return _vm.changeVideo($event, index)}}},_vm._l((_vm.videoList[index]?.channelList),function(channelItem){return _c('a-select-option',{key:channelItem.channelNo,attrs:{"title":channelItem.channelName,"value":channelItem.channelNo}},[_vm._v(_vm._s(channelItem.channelName))])}),1):_vm._e(),(_vm.videoList[index])?_c('video-view',{ref:_vm.videoList[index].id,refInFor:true,staticStyle:{"margin":"0"},attrs:{"url":_vm.videoList[index].url,"accessToken":_vm.videoList[index].token,"showOperation":true,"iconSize":_vm.gridNum === 12 ? '0.2rem' : '0.28rem'}}):_vm._e()],1)])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }