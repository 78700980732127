import request from '../request';

const screen = '/smart-site/screen/video';

const management = '/smart-site/device/vs';

// 获取设备列表
export function getDeviceList(data) {
  return request(`${management}/search/list`, {
    method: 'POST',
    body: data,
  });
}

// 获取设备在线/离线数量
export function getDeviceStatusCount(data) {
  return request(`${management}/search/online/count`, {
    method: 'POST',
    body: data,
  });
}

// 获取警报记录
export function getAlarmRecord(projectId) {
  return request(`${screen}/alarm/record/${projectId}`, {
    method: 'GET',
  });
}

// 获取更多警报记录
export function getMoreAlarmRecord(data) {
  return request(`${screen}/alarm/record/list`, {
    method: 'POST',
    body: data,
  });
}

// 获取警报统计
export function getAlarmStatistics(projectId, days) {
  return request(`${screen}/alarm/stat/${projectId}/${days}`, {
    method: 'GET',
  });
}

// 项目概览截图top5
export function getScreenshotTop(projectId) {
  return request(`${screen}/screenshotTop${projectId}`, {
    method: 'GET',
  });
}
