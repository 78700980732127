<template>
  <div :class="$style.wrapper">
    <div
      :class="[$style.sidebar, isCollapse ? '' : $style.sidebar__unCollapse]"
    >
      <div :class="$style.top">
        <span :class="$style.title" class="truncate">{{
          NVRName || '摄像头列表'
        }}</span>
        <span
          @click="onChangeDeviceList"
          :class="$style.backUpAll"
          v-if="isNVRList"
          ><a-icon type="rollback" :class="$style.icon" />全部设备</span
        >
        <a-icon
          type="menu-fold"
          @click="collapse(false)"
          :class="$style.collapse"
        />
      </div>
      <div :class="$style.tags">
        <div :class="$style.tag" @click="onChangeStatus('')">
          <div :class="$style.name">总数</div>
          <div :class="$style.num">{{ onlineNum + offlineNum }}</div>
        </div>
        <div :class="$style.tag" @click="onChangeStatus(1)">
          <div :class="$style.name">在线</div>
          <div :class="$style.num">{{ onlineNum }}</div>
        </div>
        <div :class="$style.tag" @click="onChangeStatus(0)">
          <div :class="$style.name">离线</div>
          <div :class="[$style.num, $style.offline]">{{ offlineNum }}</div>
        </div>
      </div>
      <div :class="$style.deviceList">
        <x-scroll-view
          :class="$style.list"
          :disabled="isEnd"
          :lowerThreshold="80"
          :immediateCheck="false"
          @scrolltolower="loadMoreDevices"
          class="screen_scrollbar"
        >
          <div
            :class="[
              $style.device,
              activeIndex === index && gridNum === 1 ? $style.selected : '',
            ]"
            v-for="(item, index) in deviceList"
            :key="index"
            @click="onChangeVideo(item, index)"
          >
            <i
              :class="$style.status"
              :style="{ background: item.online ? '#00c46d' : '#fa5151' }"
            ></i>
            <span
              :class="$style.name"
              class="truncate"
              :style="{ maxWidth: isNVRList ? '3rem' : '2.1rem' }"
              >{{ item.deviceName }}</span
            >
            <span :class="$style.des" v-if="!isNVRList">{{
              item.deviceType
                ? exchangeValue(item.deviceType, monitorTypes)
                : ''
            }}</span>
            <a-icon
              type="apartment"
              v-if="item.deviceType === 'NVR' && !isNVRList"
              :class="$style.icon"
              @click.stop="handleChangeNVRList(item.pkId, '', item.deviceName)"
            />
          </div>
        </x-scroll-view>
      </div>
    </div>
    <div :class="$style.main">
      <div :class="$style.top">
        <div :class="$style.title">
          <a-icon
            type="menu-unfold"
            @click="collapse(true)"
            :class="$style.unCollapse"
            v-if="!isCollapse"
          />
          <span>视频监控区</span>
        </div>
        <div :class="$style.gridIcons">
          <div
            :class="[
              $style.circle,
              gridNum === item.gridNum ? $style.selected : '',
            ]"
            @click="handleChangeLayout(item.gridNum)"
            v-for="item in gridList"
            :key="item.gridNum"
          >
            <ali-icon :type="item.icon" />
          </div>
        </div>
      </div>
      <div :class="$style.videoGrid" v-if="isReload">
        <div
          :class="$style.card"
          :style="gridObj[gridNum]"
          v-for="(item, index) in gridNum"
          :key="index"
        >
          <div :class="$style.videoWrap">
            <span v-if="videoList[index]" style="font-size: 0.16rem;">{{
              videoList[index].deviceName
            }}</span>
            <a-select
              :class="$style.channelSelect"
              @change="changeVideo($event, index)"
              v-if="
                videoList[index]?.channelList.length > 1 &&
                  videoList[index] &&
                  !isNVRList
              "
            >
              <a-select-option
                :title="channelItem.channelName"
                v-for="channelItem in videoList[index]?.channelList"
                :value="channelItem.channelNo"
                :key="channelItem.channelNo"
                >{{ channelItem.channelName }}</a-select-option
              >
            </a-select>
            <video-view
              v-if="videoList[index]"
              :url="videoList[index].url"
              :accessToken="videoList[index].token"
              :ref="videoList[index].id"
              :showOperation="true"
              :iconSize="gridNum === 12 ? '0.2rem' : '0.28rem'"
              style="margin: 0;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import VideoView from '@/components/video-view';
import {
  getLiveAddress,
  getMonitorType,
  getChannelNo,
} from '@/services/device-manage/video-surveillance';
import { getDeviceStatusCount } from '@/services/screen/video-surveillance';
import { deviceList } from '@/services/device-manage/device';
import AliIcon from '@/components/ali-icon';
import { Debounce } from 'lodash-decorators';

@Component({
  components: {
    VideoView,
    AliIcon,
  },
})
export default class VideoMonitorIndex extends Vue {
  get projectId() {
    return this.$store.state.screen.projectId;
  }

  beforeDestroy() {
    // 缓存用户筛选习惯
    if (!this.isNVRList) {
      localStorage.setItem(
        'SCREENING_HABIT',
        JSON.stringify({
          gridNum: this.gridNum,
          deviceId: this.deviceList[this.activeIndex].pkId,
        }),
      );
    }
  }

  async mounted() {
    this.getStatusCount();
    this.getDeviceType();
    await this.getDevices();
    await this.playUserHabit();
  }

  /** 播放用户习惯的模式 */
  async playUserHabit() {
    const habit = JSON.parse(localStorage.getItem('SCREENING_HABIT'));
    if (habit?.deviceId) {
      this.activeIndex = this.deviceList.findIndex(
        v => v.pkId === habit.deviceId,
      );
      // 若设备不存在,默认选中第一个设备
      this.activeIndex = this.activeIndex < 0 ? 0 : this.activeIndex;
      if (habit.gridNum === 1) {
        this.isReload = false;
        const params = {
          deviceId: habit.deviceId,
          channelNo: '',
        };
        try {
          await this.getVideoListAndChannelList(
            params,
            this.deviceList[this.activeIndex].deviceName,
          );
          this.reload();
        } catch {
          this.isReload = false;
          return false;
        }
      } else {
        this.handleChangeLayout(habit.gridNum);
      }
    } else {
      await this.playFirstVideo();
    }
  }

  isCollapse = true;
  collapse(flag) {
    this.isCollapse = flag;
    setTimeout(() => {
      this.reload();
    }, 150);
  }

  /** 播放第一台在线的设备监控 */
  async playFirstVideo() {
    this.isReload = false;
    if (this.deviceList.length) {
      let idx = 0;
      for (const info of this.deviceList) {
        if (info.online) {
          this.activeIndex = idx;
          const params = {
            deviceId: info.pkId,
            channelNo: info.channelNo,
          };
          try {
            await this.getVideoListAndChannelList(params, info.deviceName);
            this.reload();
          } catch {
            this.isReload = false;
            return false;
          }
          break;
        }
        idx++;
      }
    } else {
      this.isReload = false;
      return;
    }
  }

  /** 摄像头切换 */
  activeIndex = 0;
  @Debounce(300)
  async onChangeVideo(item, index) {
    // if (!item.online) {
    //   return;
    // }
    if (this.gridNum === 1) {
      this.videoList = [];
      this.activeIndex = index;
      try {
        const params = {
          deviceId: item.pkId,
          channelNo: item.channelNo,
        };
        await this.getVideoListAndChannelList(params, item.deviceName);
        this.reload();
      } catch {
        this.isReload = false;
        return false;
      }
    } else {
      // 以选中的设备为第一个，根据多画面的个数取其后的设备
      this.videoList = [];
      const list = this.deviceList.slice(index, this.gridNum + index);
      list.forEach(async item => {
        const params = {
          deviceId: item.pkId,
          channelNo: item.channelNo,
        };
        try {
          item.online &&
            (await this.getVideoListAndChannelList(params, item.deviceName));
        } catch {
          return false;
        }
      });
      this.reload();
      // this.$refs[item.pkId].createVideo();
    }
  }

  /** 监控栅格数量切换 */
  gridNum = 1;
  gridList = [
    {
      gridNum: 1,
      icon: 'tc-icon-a-zu3595',
    },
    {
      gridNum: 4,
      icon: 'tc-icon-a-zu3596',
    },
    {
      gridNum: 9,
      icon: 'tc-icon-a-zu3597',
    },
    {
      gridNum: 12,
      icon: 'tc-icon-a-12gongge',
    },
  ];
  gridObj = {
    1: {
      height: '100%',
      width: '100%',
    },
    4: {
      height: '49%',
      width: '49.5%',
    },
    9: {
      height: '32%',
      width: ' 32.8%',
    },
    12: {
      height: '32.6%',
      width: '24.6%',
    },
  };
  isReload = false;
  async handleChangeLayout(num) {
    this.gridNum = num;
    this.videoList = [];
    if (num === 12) {
      this.isCollapse = false;
    }
    if (num === 1) {
      // await this.playFirstVideo();
      const idx = this.activeIndex;
      const params = {
        deviceId: this.deviceList[idx].pkId,
        channelNo: this.deviceList[idx].channelNo,
      };
      await this.getVideoListAndChannelList(
        params,
        this.deviceList[idx].deviceName,
      );
      this.reload();
    } else {
      await this.getVideoUrls();
      this.reload();
    }
  }
  reload() {
    this.isReload = false;
    this.$nextTick(() => {
      this.isReload = true;
    });
  }

  /** 总数/在线/离线 设备切换 */
  @Debounce(300)
  async onChangeStatus(online = undefined) {
    this.videoList = [];
    this.gridNum = 1;
    if (this.isNVRList) {
      await this.handleChangeNVRList(this.NVRDeviceId, online);
    } else {
      await this.getDevices(online);
    }

    this.playFirstVideo();
  }

  /** NVR列表切换 */
  isNVRList = false;
  NVRDeviceId = '';
  NVRName = '';
  async handleChangeNVRList(deviceId, type, name) {
    // 缓存用户筛选习惯
    localStorage.setItem(
      'SCREENING_HABIT',
      JSON.stringify({
        gridNum: this.gridNum,
        deviceId: this.deviceList[this.activeIndex].pkId,
      }),
    );
    this.videoList = [];
    this.isNVRList = true;
    this.NVRDeviceId = deviceId;
    this.NVRName = name;
    this.gridNum = 1;
    this.deviceList = (await getChannelNo(deviceId)).map(item => ({
      pkId: deviceId,
      online: item.status === 'ONLINE',
      deviceName: item.channelName,
      channelNo: item.channelNo,
    }));
    this.deviceList.sort((obj1, obj2) => obj1.channelNo - obj2.channelNo);
    // NVR设备离线/在线数量统计
    let onlineNum = 0;
    for (const info of this.deviceList) {
      if (info.online) {
        onlineNum++;
      }
    }
    this.onlineNum = onlineNum;
    this.offlineNum = this.deviceList.length - onlineNum;
    // 在线/离线设备筛选
    if (type === 1) {
      this.deviceList = this.deviceList.filter(item => item.online);
    } else if (type === 0) {
      this.deviceList = this.deviceList.filter(item => !item.online);
    }
    await this.playFirstVideo();
  }

  /** 切换回所有摄像头列表 */
  async onChangeDeviceList() {
    this.videoList = [];
    this.isNVRList = false;
    this.NVRName = '';
    this.gridNum = 1;
    this.getStatusCount();
    await this.getDevices();
    await this.playUserHabit();
  }

  /** 获取摄像头列表 */
  queryParams = {
    current: 1,
    size: 200,
  };
  deviceList = [];
  isEnd = false;
  online = undefined;
  async getDevices(online) {
    this.online = online;
    this.queryParams.current = 1;
    try {
      const params = {
        projectId: this.projectId,
        online,
        query: this.queryParams,
      };
      const { records } = await deviceList(params, 'vs');
      this.deviceList = records;
      // 判断下一页是否还有数据
      this.isEnd = records.length < this.queryParams.size;
    } catch {
      return false;
    }
  }

  /** 摄像头列表滚动加载 */
  async loadMoreDevices() {
    this.queryParams.current += 1;
    const params = {
      projectId: this.projectId,
      online: this.online,
      query: this.queryParams,
    };
    const { records } = await deviceList(params, 'vs');
    this.deviceList = [...this.deviceList, ...records];
    this.isEnd = records.length < this.queryParams.size;
  }

  /** 批量获取监控播放地址 */
  videoList = [];
  getVideoUrls() {
    const idx = this.activeIndex;
    const list = this.deviceList.slice(idx, this.gridNum + idx);
    list.forEach(async (item, index) => {
      const params = {
        deviceId: item.pkId,
        channelNo: item.channelNo,
      };
      try {
        item.online &&
          (await this.getVideoListAndChannelList(
            params,
            item.deviceName,
            index,
          ));
      } catch {
        return false;
      }
    });
  }

  /** 获取摄像头类型 */
  monitorTypes = [];
  async getDeviceType() {
    const { monitorTypes } = await getMonitorType();
    this.monitorTypes = monitorTypes;
  }
  exchangeValue(value, option) {
    const idx = option.findIndex(v => v.value === value);
    return option[idx].name;
  }

  /** 获取在线和离线设备数量 */
  onlineNum = 0;
  offlineNum = 0;
  async getStatusCount() {
    try {
      const { onlineNum, offlineNum } = await getDeviceStatusCount({
        projectId: this.projectId,
      });
      this.onlineNum = onlineNum;
      this.offlineNum = offlineNum;
    } catch {
      return false;
    }
  }

  /** 切换视频画面 */
  async changeVideo(e, index) {
    const deviceId = this.videoList[index].id;
    const params = {
      deviceId,
      channelNo: e,
    };
    const data = await getLiveAddress(params);
    this.videoList[index].url = data.url;
    this.$refs[deviceId][0].createVideo();
  }

  /** 获取监控的播放地址列表和对应监控设备的通道号列表 */
  async getVideoListAndChannelList(params, deviceName, index = 0) {
    const data = await getLiveAddress(params);
    const channelList = (await getChannelNo(params.deviceId)) || [];
    this.videoList.push({
      ...data,
      deviceName,
      id: params.deviceId,
      channelList,
      index,
    });
    this.videoList.sort((obj1, obj2) => obj1.index - obj2.index);
  }
}
</script>

<style lang="less" module>
.wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .sidebar,
  .main {
    height: 100%;
    background: var(--screen-box-bg);
    backdrop-filter: var(--screen-filter);
    box-shadow: var(--screen-box-shadow);
    border: 1px solid var(--screen-box-border-color);
    .top {
      font-size: 0.18rem;
      height: 0.6rem;
      position: relative;
      overflow: hidden;
      padding-left: 0.16rem;
      line-height: 0.6rem;
      color: var(--screen-font-3);
      .collapse {
        position: absolute;
        right: 0.1rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.24rem;
      }
    }
  }
  .sidebar {
    width: 3.8rem;
    margin-right: 0.16rem;
    padding-bottom: 0.08rem;
    display: flex;
    flex-direction: column;
    transition: all 0.15s;
    .top {
      .title {
        width: 1.5rem;
        display: inline-block;
      }
      .backUpAll {
        position: absolute;
        right: 0.6rem;
        cursor: pointer;
        & > .icon {
          font-size: 0.2rem;
          margin-right: 0.06rem;
        }
      }
      .backUpAll:hover {
        color: #5b97e5;
      }
    }
    .tags {
      display: flex;
      justify-content: space-around;
      width: 3.8rem;
      margin-bottom: 0.16rem;
      padding: 0 0.16rem;
      .tag {
        display: flex;
        align-items: center;
        cursor: pointer;
        .name {
          width: 0.56rem;
          height: 0.32rem;
          text-align: center;
          line-height: 0.32rem;
          background: var(--screen-tag);
          border-radius: 0.24rem;
          color: var(--screen-font-3);
          font-size: 0.16rem;
        }
        .num {
          margin-left: 0.16rem;
          font-weight: 700;
          font-size: 0.24rem;
          color: #00c46d;
        }
        .offline {
          color: #fa5151;
        }
      }
    }
    .deviceList {
      width: 3.66rem;
      padding: 0.08rem 0.08rem 0.12rem;
      flex: 1;
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 0.08rem;
      background: var(--screen-box-bg);
      backdrop-filter: var(--screen-filter);
      .list {
        height: 100%;
        overflow-y: auto;
        .device {
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 0.08rem;
          height: 0.48rem;
          padding: 0 0.16rem 0;
          .name {
            max-width: 2.1rem;
            color: #fcffff;
            font-size: 0.16rem;
          }
          .des {
            color: #727d95;
            font-size: 0.14rem;
            margin-left: 0.08rem;
          }
          .status {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            margin-right: 0.2rem;
            display: inline-block;
          }
          & > .icon {
            position: absolute;
            font-size: 0.2rem;
            right: 0.1rem;
          }
          & > .icon:hover {
            color: #5b97e5;
          }
        }
        .device:hover {
          cursor: pointer;
          background: #223142;
          .name {
            text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
          }
        }
        .selected {
          background: #223142;
          .name {
            text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
          }
        }
      }
    }
  }
  .sidebar__unCollapse {
    width: 0;
    margin-right: 0;
    border: 0px solid var(--screen-box-border-color);
  }
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    .top {
      display: flex;
      align-items: center;
      .unCollapse {
        font-size: 0.24rem;
        transform: translateY(0.028rem);
        margin-right: 0.1rem;
      }
      .title {
        margin-right: 0.16rem;
      }
      .gridIcons {
        width: 1.28rem;
        height: 0.32rem;
        display: flex;
        background: var(--screen-tag);
        border-radius: 0.24rem;
        .circle {
          height: 0.32rem;
          width: 0.32rem;
          border-radius: 50%;
          text-align: center;
          line-height: 0.32rem;
          color: #90a1b1;
        }
        .circle:hover {
          cursor: pointer;
          background-color: #394655;
          color: var(--screen-font-3);
        }
        .selected {
          background-color: #394655;
          color: var(--screen-font-3);
        }
      }
    }
    .videoGrid {
      flex: 1;
      padding: 0 0.16rem 0.16rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
      .card {
        background: var(--screen-box-bg);
      }
    }
  }
  .videoWrap {
    height: 100%;
    width: 100%;
    position: relative;
    & > span {
      display: block;
      padding: 4px 8px;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.8);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
    }
    .channelSelect {
      top: 0.04rem;
      right: 0.04rem;
      position: absolute;
      z-index: 9999;
      width: 1.8rem;
      margin-left: 0;
      background: rgba(2, 50, 70, 0.8);
      :global {
        .ant-select-selection::after {
          width: 0;
        }
      }
    }
  }
}
</style>
